import Each from "lodash/each";
import Map from "lodash/map";
import Last from "lodash/last";
import Get from "lodash/get";
import Find from "lodash/find";
import Includes from "lodash/includes";
import Filter from "lodash/filter";
import Debounce from "lodash/debounce";
import axios from "axios";

import PolygonWebsockets from "./websockets.js";
import { maxBy } from "lodash";

const BASE_URL = `https://api.polygon.io`;
const POLL_INTERVAL = 10; // seconds
const _baseURL = `https://umbrella-be.ammag.tech/polygon`;

// all resolutions supported by the datafeed
const SUPPORTED_RESOLUTIONS = [
  "1",
  "3",
  "5",
  "15",
  "30",
  "45",
  "60",
  "120",
  "180",
  "240",
  "1D",
  "1W",
  "1M",
];

const configurationData = {
  supported_resolutions: [
    "1",
    "3",
    "5",
    "15",
    "30",
    "45",
    "60",
    "120",
    "180",
    "240",
    "1D",
    "1W",
  ],
  // exchanges: [{
  // 	value: 'Bitfinex',
  // 	name: 'Bitfinex',
  // 	desc: 'Bitfinex',
  // },
  // {
  // 	// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
  // 	value: 'Kraken',

  // 	// filter name
  // 	name: 'Kraken',

  // 	// full exchange name displayed in the filter popup
  // 	desc: 'Kraken bitcoin exchange',
  // },
  // ],
  //   symbols_types: [
  //     {
  //       name: "crypto",

  //       // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
  //       value: "crypto",
  //     },
  //     {
  //       name: "forex",
  //       value: "forex",
  //     },
  //     // ...
  //   ],
};

class PolygonAdapter {
  /**
   *  Polygon Adapter
   *  @param  {Object} params 		Object containing { apikey }
   *  @return {PolygonAdapter}        return created instance for chaining methods
   */
  constructor(params) {
    this.subscriptions = [];
    this.apikey = params.apikey;
    this.realtimeEnabled = false; //params.realtimeEnabled || false;
    this.searchSymbols = Debounce(this._searchSymbols, 250, {
      trailing: true,
    });
    return this;
  }

  /**
   *  onReady method for TV lib
   *  @param  {Function} cb Callback when we are ready
   *  @return {null}
   */
  onReady(cb) {
    // console.log("Polygon Adapter Ready");
    // if (this.realtimeEnabled) {
    //   this.wsListeners();
    // } else {
    setInterval(this.onInterval.bind(this), POLL_INTERVAL * 1000);
    // }
    cb(configurationData);
  }

  /**
   *  On each interval we loop through our subscriptions and request bars for the past 2min
   *  @return {null}
   */
  onInterval() {
    //get current or next 1 candle and add it if not already
    // Each(this.subscriptions, (sub) => {
    //   let now = Date.now();
    //   this.getBars(
    //     sub.symbolInfo,
    //     sub.interval,
    //     {
    //       from: parseInt(
    //         now / 1000 - this.resolutionToSeconds(sub.interval) * 1
    //       ),
    //       to: parseInt(now / 1000),
    //     },
    //     (ticks) => {
    //       if (ticks.length == 0) return;
    //       if (this.currentBar) {
    //         ticks = Filter(ticks, (t) => t.time > this.currentBar.time).sort(
    //           (x, y) => y.time - x.time
    //         );
    //         if (ticks.length == 0) return;
    //         if (ticks.length > 0) this.currentBar = ticks[0];
    //       } else return;
    //       sub.callback(ticks[0]);
    //     }
    //   );
    // });
    // get current bar but in 1m interval and update currentBar
    // Each(this.subscriptions, (sub) => {
    //   let now = Date.now();
    //   this.getBars(
    //     sub.symbolInfo,
    //     "1",
    //     {
    //       from: parseInt(now / 1000 - this.resolutionToSeconds("1") * 4),
    //       to: parseInt(now / 1000) + 100,
    //     },
    //     (ticks) => {
    //       if (ticks.length == 0) return;
    //       if (this.currentBar) {
    //         let latestBar = ticks.sort((x, y) => y.time - x.time)[0];

    //         console.log(
    //           "🚀 ~ PolygonAdapter ~ Each ~ latestBar:",
    //           latestBar.time,
    //           this.currentBar.time
    //         );

    //         //check if latest bar is within current bar so it can be an update
    //         let isWithinCurrentBar = latestBar.time >= this.currentBar.time;
    //         //   &&
    //         //   latestBar.time <
    //         //     this.currentBar.time +
    //         //       this.resolutionToSeconds(sub.interval) * 1000;

    //         console.log(
    //           "🚀 ~ PolygonAdapter ~ Each ~ isWithinCurrentBar:",
    //           isWithinCurrentBar,
    //           this.currentBar.close != latestBar.close,
    //           this.currentBar.close,
    //           latestBar.close
    //         );
    //         if (!isWithinCurrentBar) return;
    //         // update this currentBar from info in latestBar, like low will be updated if it is lower than currentBar.low
    //         this.currentBar = {
    //           time: this.currentBar.time,
    //           // +
    //           // this.resolutionToSeconds(sub.interval) * 1000,
    //           close: latestBar.close,
    //           open: this.currentBar.open,
    //           high: Math.max(this.currentBar.high, latestBar.high),
    //           low: Math.min(this.currentBar.low, latestBar.low),
    //           volume: this.currentBar.volume,
    //         };
    //         sub.callback(this.currentBar);
    //       } else return;
    //       // sub.callback(ticks[0]);
    //     }
    //   );
    // });
    Each(this.subscriptions, (sub) => {
      let now = Date.now();
      if (!this.currentBar) return;
      let from = parseInt(this.currentBar.time);
      let IsPartial =
        now - from < this.resolutionToSeconds(sub.interval) * 1000;

      if (IsPartial == false)
        from = from + this.resolutionToSeconds(sub.interval) * 1000;
      let to = parseInt(now); //from + this.resolutionToSeconds(sub.interval) * 1 - 1;

      this.getBars(
        sub.symbolInfo,
        "s",
        {
          from: parseInt(from / 1000),
          to: parseInt(now / 1000),
        },
        (ticks) => {
          if (ticks.length == 0) return;
          if (this.currentBar) {
            // if (!this.partialBar) {
            let partialBar = {
              time: from,
              close: 0,
              open: 0,
              high: 0,
              low: 0,
              volume: 0,
            };
            // }

            ticks.filter((x) => x.time >= from).sort((x, y) => x.time - y.time);
            partialBar.open = ticks[0].open;
            partialBar.low = ticks[0].low;
            for (const tick of ticks) {
              if (tick.time >= from && tick.time <= to) {
                partialBar.close = tick.close;
                partialBar.high = Math.max(partialBar.high, tick.high);
                partialBar.low = Math.min(partialBar.low, tick.low);
                partialBar.volume += tick.volume;
              }
            }
            if (IsPartial == false) {
              this.currentBar = partialBar;
            }
            // document.title = `(${partialBar.close}) ${sub.symbolInfo.ticker}`;
            sub.callback(partialBar);
          } else return;
        }
      );
    });
  }

  /**
   *  Debounced searchSymbols method for TV lib
   *  @param  {String}   input      Users search input
   *  @param  {String}   exchange   Exchange search input
   *  @param  {String}   symbolType Symbol type ( `stock`, `bitcoing`, `forex`)
   *  @param  {Function} cb         Callback for returning results
   *  @return {null}
   */
  _searchSymbols(input, exchange, symbolType, cb) {
    // axios({
    //   url: `${BASE_URL}/v3/reference/tickers?search=${input}&active=true&apikey=${this.apikey}`,
    // })
	axios({
		url: `${_baseURL}/reference/tickers/search/${input}`,
	  })
      .then((res) => {
        // console.log("search results:", res);
        cb(
          Map(res.data.results, (item) => {
            return {
              symbol: item.ticker,
              ticker: item.ticker,
              full_name: item.name,
              description: `${item.name}`,
              exchange: item.primary_exchange,
              type: item.market,
              locale: item.locale,
            };
          })
        );
      })
      .catch((err) => {
        console.log("not found:", err);
        cb([]);
      });
  }

  /**
   *  Resolving a symbol simply gets the company info for this symbol
   *  @param  {String}   symbol Symbol string we are requesting
   *  @param  {Function} cb     Callback for symbol info
   *  @param  {Function}   cberr  Callback for errors occured
   *  @return {null}
   */
  resolveSymbol(symbol, cb, cberr) {
    console.log("resolve symbol:", symbol);
    let TickerTypeMap = {
      // 'STOCKS': 'stock',
      FX: "forex",
      CRYPTO: "crypto",
    };
    // axios
    //   .get(`${BASE_URL}/v3/reference/tickers/${symbol}?apiKey=${this.apikey}`)
    axios
      .get(`${_baseURL}/reference/tickers/${symbol}`)
      .then((data) => {
        let c = Get(data, "data.results", {});
        // let intFirst = Get(c, 'aggs.intraday.first', false)
        // let dayFirst = Get(c, 'aggs.daily.first', false)

        cb({
          name: c.name,
          ticker: c.ticker,
          type: TickerTypeMap[c.market] || "forex",
          exchange: c.primary_exchange,
          //   timezone: "America/New_York",
          //   session: "24x7",
          //   timezone: "UTC",
          //   first_intraday: c.list_date,
          has_intraday: true,
          //   first_daily: c.list_date,
          // has_daily: (unixTimestampMs != false),
          supported_resolutions: configurationData.supported_resolutions,
          //   minmov: 1,
          //   pricescale: 100,
          //   has_no_volume: true,
        });
      });
  }

  /**
   * Get the resolution in seconds
   * @param  {String} resolution Resolution string
   * @return {Int}            Resolution in seconds
   * @example
   * resolutionToSeconds('1D') => 86400
   * resolutionToSeconds('1W') => 604800
   * resolutionToSeconds('1M') => 259200
   * resolutionToSeconds('1') => 60
   */
  resolutionToSeconds(resolution) {
    if (resolution == "s") return 1;
    if (resolution == "D" || resolution == "1D") return 86400;
    if (resolution == "W" || resolution == "1W") return 604800;
    if (resolution == "M" || resolution == "1M") return 2592000;
    if (
      Includes(
        ["1", "3", "5", "15", "30", "45", "60", "120", "180", "240"],
        resolution
      )
    ) {
      return parseInt(resolution) * 60;
    }
  }

  /**
   * Resolve a resolution string to a multiplier and timespan
   * @param  {String} resolution Resolution string
   * @return {Object}            Object containing { multiplier, timespan }
   * @example
   * resolveResolutionString('1D') => { multiplier: 1, timespan: 'day' }
   */
  resolveResolutionString(resolution) {
    let multiplier = 1;
    let timespan = "minute";
    if (resolution == "D" || resolution == "1D") timespan = "day";
    if (Includes(["1", "3", "5", "15", "30", "45"], resolution)) {
      multiplier = parseInt(resolution);
      timespan = "minute";
    }
    if (Includes(["60", "120", "180", "240"], resolution)) {
      timespan = "hour";
      multiplier = parseInt(resolution) / 60;
    }
    if (resolution == "s") {
      timespan = "second";
      multiplier = 1;
    }
    return { multiplier, timespan };
  }

  /**
   *  Get aggregate bars for our symbol
   *  @param  {Object}   symbolInfo   Object returned from `resolveSymbol`
   *  @param  {String}   resolution   Interval size for request ( `1`, `1D`, etc )
   *  @param  {Int}   from         Unix timestamp to search from
   *  @param  {Function} cb           Callback with resolved bars
   *  @param  {Function}   cberr        Callback for errors
   *  @param  {Boolean}   firstRequest If this is the first request for this symbol
   *  @return {null}
   */
  getBars(symbolInfo, resolution, fromts, cb, cberr) {
    let { multiplier, timespan } = this.resolveResolutionString(resolution);
    // axios({
    //   url: `${BASE_URL}/v2/aggs/ticker/${
    //     symbolInfo.ticker
    //   }/range/${multiplier}/${timespan}/${fromts.from * 1000}/${
    //     fromts.to * 1000
    //   }`,
    //   params: {
    //     apikey: this.apikey,
    //     limit: 500000,
    //     sort: fromts.countBack ? "desc" : "asc",
    //   },
    // })
    axios({
      url: `${_baseURL}/aggs/ticker/${
        symbolInfo.ticker
      }/range/${multiplier}/${timespan}/${fromts.from * 1000}/${
        fromts.to * 1000
      }`,
      params: {
        limit: 500000,
        sort: fromts.countBack ? "desc" : "asc",
      },
    })
      .then((data) => {
        let bars = [];
        bars = Map(data.data.results, (t) => {
          return {
            time: t.t,
            close: t.c,
            open: t.o,
            high: t.h,
            low: t.l,
            volume: t.v,
          };
        }).sort((x, y) => x.time - y.time);
        // check if fromts is from charting library by checking countBack
        //   then save the most current bar by checking latest time
        if (fromts.countBack || fromts.firstDataRequest) {
          let LastBar = maxBy(bars, "time");
          this.latestBar = maxBy(bars, "time");
          if (this.currentBar) {
            if (LastBar.time >= this.currentBar.time) {
              this.currentBar = LastBar;
            }
          } else this.currentBar = maxBy(bars, "time");
        }

        return cb(bars, {
          noData: false /* ( bars.length == 0 && timespan != 'minute' ) */,
        });
      })
      .catch(cberr);
  }

  /**
   *  Subscribe to future updates for this symbol
   *  @param  {Object}   symbolInfo Object returned from `resolveSymbol`
   *  @param  {String}   interval   Interval size for request
   *  @param  {Function} cb         Callback when we have new bars
   *  @param  {String}   key        Unique key for this subscription
   *  @return {null}
   */
  subscribeBars(symbolInfo, interval, cb, key) {
    // if (this.currentInterval && this.currentInterval != interval) {
    //   //   this.currentBar = null;
    //   this.currentInterval = interval;
    //   this.subscriptions = [];
    // } else {
    //   this.currentInterval = interval;
    //   this.subscriptions = [];
    // }

    let sub = {
      key: `${key}`,
      symbolInfo: symbolInfo,
      interval: interval,
      callback: cb,
    };
    // Currently only allow minute subscriptions:
    // if (sub.interval != "1") return;
    // if (this.realtimeEnabled) this.ws.subscribe(`AM.${symbolInfo.ticker}`);
    this.subscriptions.push(sub);
  }

  /**
   *  Unsubscribe from future updates for a symbol
   *  @param  {String} key Unique key for this subscription
   *  @return {null}
   */
  unsubscribeBars(key) {
    this.subscriptions = Filter(this.subscriptions, (s) => s.key != key);
  }

  /**
   *  Add the websocket listeners and start the connection:
   *  @return {null}
   */
  wsListeners() {
    if (!this.realtimeEnabled) return;
    console.log("Starting Polygon Websocket connection..");
    // this.ws = new PolygonWebsockets({ apiKey: this.apikey });
    // this.ws.on("AM", (aggMin) => {
    //   Each(this.subscriptions, (sub) => {
    //     sub.callback({
    //       open: aggMin.o,
    //       close: aggMin.c,
    //       high: aggMin.h,
    //       low: aggMin.l,
    //       volume: aggMin.v,
    //       time: aggMin.s,
    //     });
    //   });
    // });
  }
}

export default PolygonAdapter;
