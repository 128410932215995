import React, { useEffect, useRef } from 'react';
import './index.css';
import { widget } from '../../charting_library';
import PolygonAdapter from '../../polygon/tradingview-adapter';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const TVChartContainer = () => {
	const chartContainerRef = useRef();

	const defaultProps = {
		symbol: 'C:XAUUSD',
		interval: '15',
		datafeedUrl: 'https://tradingview-datafeed.ammag.tech',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: true,
		autosize: true,
		studiesOverrides: {},
	};

	useEffect(() => {
		const _pK = [];
		const _pD = [];
		
		const client = new PolygonAdapter({
			// apikey: '',
			realtimeEnabled: false 	// True(default) = Use websockets for updates. False = use polling for new data.
		  });
		const widgetOptions = {
			symbol: defaultProps.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: client,//new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
			interval: defaultProps.interval,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,

			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			theme:'dark',
			debug:false,
			custom_indicators_getter: function (PineJS) {
				return Promise.resolve([
					{
						name: 'KDJ Indicators',
						metainfo: {
							_metainfoVersion: 51,
							id: 'KDJIndicators@tv-basicstudies-1',
							description: 'KDJ Indicators',
							shortDescription: 'KDJ Indicators',
							is_price_study: false,
							isCustomIndicator: true,
							plots: [
								{
									id: 'plot_0',
									type: 'line',
								},
								{
									id: 'plot_1',
									type: 'line',
								},
								{
									id: 'plot_2',
									type: 'line',
								},
								{
									id: 'plot_3',
									type: 'chars',
								}, {
									id: 'plot_4',
									type: 'chars',
								},
								{
									id: "plot_5",
									type: "bg_colorer",
									palette: "paletteId1",
								},
							],
							palettes: {
								paletteId1: {
									valToIndex: {
										0: 0,
										1: 1,
									},
									colors: {
										0: {
											name: "First color",
										},
										1: {
											name: "Second color",
										},
									},
								},
							},
							defaults: {
								palettes: {
									paletteId1: {
										colors: {
											0: {
												color: "#d4af37",
												width: 3,
												style: 1,
											},
											1: {
												color: "#000",
												width: 3,
												style: 2,
											},
										},
									},
								},
								styles:
								{
									plot_0: {
										linestyle: 0,
										visible: true,
										linewidth: 2,
										plottype: 2,
										// trackPrice: true,
										transparency: 0,
										color: '#fff'
									},
									plot_1: {
										linestyle: 0,
										visible: true,
										linewidth: 2,
										plottype: 2,
										color: "#ffffff",
										transparency: 0,
									},
									plot_2: {
										linestyle: 0,
										visible: true,
										linewidth: 2,
										plottype: 2,
										// trackPrice: true,
										transparency: 0,
										color: '#d4af37'
									},
									plot_3: {
										visible: true,
										char: "☂︎",
										location: "Absolute",
										color: "#d4af37",
										textColor: "white",
										transparency: 0,
									},
									plot_4: {
										visible: true,
										char: "☂︎",
										location: "Absolute",
										color: "#fff",
										textColor: "#fff",
										transparency: 0,
									},
									plot_5: {
										visible: true,
										palette: "paletteId1",
									},
								},
								precision: 0,
								inputs: {}
							},
							styles:
							{
								plot_0:
								{
									title: 'First plot',
									histogramBase: 0,
								},
								plot_1:
								{
									title: 'Second plot',
									histogramBase: 0,
								},
								plot_2:
								{
									title: 'Third plot',
									histogramBase: 0,
								},
								plot_3:
								{
									title: 'Fourth plot',
									histogramBase: 0,
									size: 'small',
									text: ""

								},
								plot_4:
								{
									title: 'Fifth plot',
									histogramBase: 0,
									size: 'small',
									text: ""

								},
							},
							inputs: [],
							format: {
								type: 'price',
								precision: 0,
							},
						},


						constructor: function () {
							this.main = function (context, inputCallback) {
								this._context = context;
								this._input = inputCallback;
								function shouldShowUmbrella(pJ, pD) {
									const precision = 10;
									return Math.round(pJ) > Math.round(pD);
								}

								function shouldShowUmbrella2(pJ, pD) {
									const precision = 10;
									return Math.round(pJ) < Math.round(pD);
								}

								const ilong = 9;
								const isig = 3;
								var hL = PineJS.Std.highest(context.new_var(context.symbol.high), ilong, context)
								var LL = PineJS.Std.lowest(context.new_var(context.symbol.low), ilong, context)

								function bcwsma(s, l, m, previousValue, p) {

									const newValue = (m * s + (l - m) * (previousValue || 0)) / l;
									if (p == 'pK') {
										_pK.push(newValue)
									} else if (p == 'pD') {
										_pD.push(newValue)
									}
									//_bcwsma.push(newValue);
									return newValue;
								}

								function calculateIndicator(close, high, low, ilong, isig) {
									const c = close;
									const h = high;
									const l = low;
									const RSV = 100 * ((c - l) / (h - l)); 

									let pK = bcwsma(RSV, isig, 1, _pK[_pK.length - 1], 'pK')

									let pD = bcwsma(pK, isig, 1, _pD[_pD.length - 1], 'pD')


									let pJ = 3 * pK - 2 * pD;
									var colorIndex = null;

									var crossOver = null;
									var crossUnder = null;
									if (PineJS.Std.cross(pJ, pD, context)) {
										// prevVal = pJ;
										if (pJ > pD) { // crossover
											crossOver = pD
										} else if (pJ < pD) // crossunder
										{
											crossUnder = pD
										}
									} else {
										// prevVal = null;
									}
									if (pJ > pD) { // crossover
										colorIndex = 1
									} else if (pJ < pD) // crossunder
									{
										colorIndex = 0
									}
									return { pK, pD, pJ, crossOver, crossUnder, colorIndex };
								}



								const { pK, pD, pJ, crossOver, crossUnder, colorIndex } = calculateIndicator(context.symbol.close, hL, LL, ilong, isig);

								return [pK, pD, pJ, crossOver, crossUnder, colorIndex];
							}
						}
					}
				,{
						name: 'KDJ Ichimoku Cloud',
						metainfo: {
							_metainfoVersion: 51,
							id: 'KDJIchimokuCloud@tv-basicstudies-1',
							description: 'KDJ Ichimoku Cloud',
							shortDescription: 'KDJ Ichimoku Cloud',
							is_price_study: true,
							isCustomIndicator: true,

							plots: [
								// {
								// 	id: 'plot_0',
								// 	type: 'line',
								// },
								// {
								// 	id: 'plot_1',
								// 	type: 'line',
								// },
								// {
								// 	id: 'plot_2',
								// 	type: 'line',
								// },
								{
									id: 'plot_3',
									type: 'line',
								},
								{
									id: 'plot_4',
									type: 'line',
								},
								{
									id: 'plot_5',
									type: 'colorer',
									target: 'filledAreaId1',
									palette: 'paletteId1',
								},

							],
							palettes: {
								paletteId1: {
									valToIndex: {
										0: 0,
										1: 1
									},
									colors: {
										0: {
											name: "Color 0",
										},
										1: {
											name: "Color 1",
										},

									},
								},
							},
							filledAreas: [
								{
									id: 'filledAreaId1',
									objAId: 'plot_3',
									objBId: 'plot_4',
									title: 'Plots Background',
									type: 'plot_plot',
									palette: 'paletteId1',
								},
							],
							defaults: {
								filledAreasStyle: {
									filledAreaId1: {
										color: 'white',
										visible: true,
										transparency: 70,
									},
								},
								palettes: {
									paletteId1: {
										valToIndex: {
											0: 0,
											1: 1,
										},
										colors: {
											0: {
												color: "#ffffff",
												width: 3,
												style: 1,
											},
											1: {
												color: "#d4af37",
												width: 3,
												style: 1,
											},

										},
									},
								},
								styles:
								{
									// plot_0: {
									// 	linestyle: 0,
									// 	visible: true,
									// 	linewidth: 1,
									// 	plottype: 2,
									// 	trackPrice: false,
									// 	transparency: 0,
									// 	color: '#2962FF'
									// },
									// plot_1: {
									// 	linestyle: 0,
									// 	visible: true,
									// 	linewidth: 2,
									// 	plottype: 2,
									// 	color: "#B71C1C",
									// 	transparency: 0,
									// },
									// plot_2: {
									// 	linestyle: 0,
									// 	visible: true,
									// 	linewidth: 1,
									// 	plottype: 2,
									// 	trackPrice: false,
									// 	transparency: 0,
									// 	color: '#43A047'
									// },
									plot_3: {
										linestyle: 0,
										visible: true,
										linewidth: 1,
										plottype: 2,
										color: "#A5D6A7",
										transparency: 0,
									},
									plot_4: {
										linestyle: 0,
										visible: true,
										linewidth: 1,
										plottype: 2,
										color: "#EF9A9A",
										transparency: 0,
									},


								},
								precision: 0,
								inputs: {
									conversionPeriods:9,
									basePeriods:26,
									laggingSpan2Periods:52,
									displacement:26
								}
							},
							styles:
							{
								// plot_0:
								// {
								// 	title: 'Conversion Line',
								// 	histogramBase: 0,
								// },
								// plot_1:
								// {
								// 	title: 'Base Line',
								// 	histogramBase: 0,
								// },
								// plot_2:
								// {
								// 	title: 'Lagging Span',
								// 	histogramBase: 0,
								// },
								plot_3:
								{
									title: 'Leading Span A',
									histogramBase: 0,
								},
								plot_4:
								{
									title: 'Leading Span B',
									histogramBase: 0,
								},

							},
							inputs: [
								{
								id: 'conversionPeriods',
								name: 'ConversionPeriods',
								type: 'integer',
								min: 1,
								defval: 9
							},
							{
								id: 'basePeriods',
								name: 'BasePeriods',
								type: 'integer',
								min: 1,
								defval: 26,
							},
							{
								id: 'laggingSpan2Periods',
								name: 'LaggingSpan2Periods',
								type: 'integer',
								min: 1,
								defval: 52,
							},
							{
								id: 'displacement',
								name: 'Displacement',
								type: 'integer',
								min: 1,
								defval: 26,
							}],
							format: {
								type: 'price',
								precision: 2,
							},
						},

						constructor: function () {
							this.main = function (context, inputCallback) {
								this._context = context;
								this._input = inputCallback;

								var conversionPeriods =inputCallback(0)
								var basePeriods = this._input(1)
								var laggingSpan2Periods = this._input(2)
								var displacement = this._input(3);
								
								const donchian = (length) => {
									var HL = PineJS.Std.highest(context.new_var(context.symbol.high), length, context)
									var LL = PineJS.Std.lowest(context.new_var(context.symbol.low), length, context)
									

									return PineJS.Std.avg(HL, LL)
								}

								let conversionLine = donchian(conversionPeriods)
								let baseLine = donchian(basePeriods)

								let leadLine1 = PineJS.Std.avg(conversionLine, baseLine)
								let leadLine2 = donchian(laggingSpan2Periods)
						
								var colorIndex = leadLine1 > leadLine2 > 0 ? 0 : 1;

								return [
									// conversionLine,
									// baseLine,
									// { value: context.symbol.close, offset: -1*(displacement-1) },
									{ value: leadLine1, offset: displacement - 1 },
									{ value: leadLine2, offset: displacement - 1 },
									colorIndex
								];
							}
						}
					}]);
			}
		
		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.chart().createStudy('KDJ Indicators', false, false);
			tvWidget.chart().createStudy('KDJ Ichimoku Cloud', false, false);
		});
		

		return () => {
			tvWidget.remove();
		};
	});

	return (
		<div
			ref={chartContainerRef}
			className={'TVChartContainer'}
		/>
	);
}
